import React from 'react';

import { message, Checkbox, Button, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { Trash } from 'react-feather';

import {
	START_INDEX,
	END_INDEX,
	SCHEDULE_DAY_OPTIONS,
	DEFAULT_SCHEDULE,
	MIDNIGHT,
	LATEST_ROLLOVER_TIME,
	ALWAYS_OPEN_TIME,
	DAYS_AHEAD_TO_PUBLISH_SCHEDULE,
} from 'constants/menuManagement.constants';

import { capitalizeWords } from 'utils/helpers';

export const calculateDefaultDate = (defaultDate, schedules) => {
	const existingDates = schedules.map((schedule) => schedule.appliedDate);
	if (!existingDates.includes(defaultDate)) {
		return defaultDate;
	}
	return calculateDefaultDate(
		dayjs(defaultDate).add(1, 'day').format('YYYY-MM-DD'),
		schedules,
	);
};

export const removeIsNewFlag = (schedules) => {
	return schedules.map((schedule) => {
		delete schedule.isNew;
		return schedule;
	});
};

// if the end is before the start
// it rolls over to the next day
export const isEndTimePastMidnight = ({ start, end }) => {
	const startTimeAsDate = new Date(`01/01/1970 ${start}`);
	const startTimeValidDate =
		startTimeAsDate instanceof Date && !isNaN(startTimeAsDate);

	const endTimeAsDate = new Date(`01/01/1970 ${end}`);
	const endTimeValidDate =
		endTimeAsDate instanceof Date && !isNaN(endTimeAsDate);

	if (startTimeValidDate && endTimeValidDate) {
		return endTimeAsDate - startTimeAsDate < 0;
	}
	return false;
};

export const isRolloverValid = ({ start, end }) => {
	const rollover = isEndTimePastMidnight({ start, end });
	return !(rollover && end > LATEST_ROLLOVER_TIME);
};

export const getNewMenuSchedules = () =>
	SCHEDULE_DAY_OPTIONS.map((option) => ({
		dayIndex: option.value,
		startTime: '09:00',
		endTime: '17:00',
	}));

export const sortSchedulesArray = (schedules = []) => {
	if (schedules.length) {
		schedules.sort((a, b) => {
			const day1 = a.dayIndex.toLowerCase();
			const day2 = b.dayIndex.toLowerCase();
			if (day1 === day2) {
				// days are equal, sort by start time
				const start1 = new Date(`1/1/1 ${a.startTime}`);
				const start2 = new Date(`1/1/1 ${b.startTime}`);
				return start1.getTime() - start2.getTime();
			}
			const ordinalDay1 = SCHEDULE_DAY_OPTIONS.find(
				(option) => option.value === day1,
			)?.ordinal;
			const ordinalDay2 = SCHEDULE_DAY_OPTIONS.find(
				(option) => option.value === day2,
			)?.ordinal;

			return ordinalDay1 - ordinalDay2;
		});
	}
	return schedules;
};

export const sortSpecialSchedulesArray = (schedules = []) => {
	if (schedules.length) {
		schedules.sort((a, b) => {
			if (!a.appliedDate || !b.appliedDate)
				// send new dates to the end of the array
				return 1;
			const day1 = dayjs(a.appliedDate);
			const day2 = dayjs(b.appliedDate);
			if (day1 === day2) {
				// days are equal, sort by start time
				const start1 = new Date(`1/1/1 ${a.startTime}`);
				const start2 = new Date(`1/1/1 ${b.startTime}`);
				return start1.getTime() - start2.getTime();
			}
			return day1 - day2;
		});
	}
	return schedules;
};
export const getScheduledPublishDate = (appliedDate) => {
	appliedDate = dayjs(appliedDate);
	return appliedDate
		.subtract(DAYS_AHEAD_TO_PUBLISH_SCHEDULE, 'day')
		.format('YYYY-MM-DD');
};

export const dateIsFuture = (date) => {
	const today = dayjs();
	const dayToCheck = dayjs(date);
	return dayToCheck.isAfter(today);
};

export const givenDateDueToPublish = (date) => {
	const today = dayjs();
	const dayToCheck = dayjs(date);
	return (
		dayToCheck.isAfter(today) &&
		dayToCheck.isBefore(today.add(DAYS_AHEAD_TO_PUBLISH_SCHEDULE, 'days'))
	);
};

export const upcomingPublishTooltip = (date, isPublished) => {
	const appliedDate = dayjs(date);
	const dayName = capitalizeWords(
		SCHEDULE_DAY_OPTIONS.find(
			(dayIndex) => dayIndex.ordinal === appliedDate.day(),
		)?.value || appliedDate.format('dddd'),
	);
	const scheduledPublishDate = getScheduledPublishDate(appliedDate);
	if (isPublished) {
		return `This menu should have been automatically published with ${dayName} set to these hours. If published now, these hours will continue override the default menu hours for this ${dayName}.`;
	} else if (givenDateDueToPublish(date)) {
		return `This menu will be automatically published tonight at 1am, with ${dayName} set to these hours. Publishing the menu now will override the default menu hours for this ${dayName}.`;
	}
	return `This menu will be automatically published on ${scheduledPublishDate} at 1am, with ${dayName} set to these hours. Publishing the menu now will not include these hours (it is more than 6 days away).`;
};

export const pastHoursTooltip = (day) => {
	const appliedDate = dayjs(day);
	const dayName = capitalizeWords(
		SCHEDULE_DAY_OPTIONS.find(
			(dayIndex) => dayIndex.ordinal === appliedDate.day() + 1,
		)?.value || appliedDate.format('dddd'),
	);
	const scheduledPublishDate = appliedDate.add(2, 'day').format('YYYY-MM-DD');
	return `This menu should have been published on ${scheduledPublishDate} at 1am with standard hours for ${dayName}.`;
};

export const isStoreClosedHelper = (hoursObject) => {
	return hoursObject.startTime === MIDNIGHT && hoursObject.endTime === MIDNIGHT;
};

export const isOpen24hoursHelper = (hoursObject) => {
	return (
		hoursObject.startTime === MIDNIGHT &&
		hoursObject.endTime === ALWAYS_OPEN_TIME
	);
};

export const onCheckboxChangeHelper = (schedules, index) => {
	const newSchedules = [...schedules];
	if (isStoreClosedHelper(newSchedules[index])) {
		newSchedules[index].startTime = DEFAULT_SCHEDULE.startTime;
		newSchedules[index].endTime = DEFAULT_SCHEDULE.endTime;
	} else {
		newSchedules[index].startTime = MIDNIGHT;
		newSchedules[index].endTime = MIDNIGHT;
	}
	newSchedules[index].isPublished = false;
	return newSchedules;
};

export const on24HoursCheckboxChangeHelper = (schedules, index) => {
	const newSchedules = [...schedules];
	if (isOpen24hoursHelper(newSchedules[index])) {
		newSchedules[index].startTime = DEFAULT_SCHEDULE.startTime;
		newSchedules[index].endTime = DEFAULT_SCHEDULE.endTime;
	} else {
		newSchedules[index].startTime = MIDNIGHT;
		newSchedules[index].endTime = ALWAYS_OPEN_TIME;
	}
	newSchedules[index].isPublished = false;
	return newSchedules;
};

export const handleDeleteScheduleHelper = (schedules, index) => {
	const newSchedules = [...schedules];
	newSchedules[index] = { ...newSchedules[index], isDeleted: true };
	return newSchedules;
};

export const onHoursChangeHelper = (schedules, index, newHours) => {
	const newStart = newHours[START_INDEX]
		? newHours[START_INDEX].format('HH:mm')
		: null;
	const newEnd = newHours[START_INDEX]
		? newHours[END_INDEX].format('HH:mm')
		: null;

	if (newStart && newEnd) {
		if (!isRolloverValid({ start: newStart, end: newEnd })) {
			message.error({
				content: `Cannot rollover past ${LATEST_ROLLOVER_TIME} AM.`,
			});
			return schedules;
		}
	}

	const newSchedules = [...schedules];
	newSchedules[index] = {
		...newSchedules[index],
		startTime: newStart,
		endTime: newEnd,
		isPublished: false,
	};

	return newSchedules;
};

export const createCheckboxItemsHelper = (
	schedule,
	index,
	disabled,
	storeClosableMap,
	isStoreClosed,
	isOpen24hours,
	onCheckboxChange,
	on24HoursCheckboxChange,
	handleDeleteSchedule,
	isSourceChowNow = false,
) => {
	schedule.appliedDate = dayjs(schedule.appliedDate).format('YYYY-MM-DD');
	const action = isStoreClosed(schedule) ? 'Closed' : 'Close';
	const closedLabel = schedule.dayIndex
		? `${action} on ${capitalizeWords(schedule.dayIndex)}`
		: `${action} on ${schedule.appliedDate}`;

	const disabledOrCantBeDeleted =
		storeClosableMap[schedule.dayIndex] === false || disabled;

	const tooltipTitle = (() => {
		if (storeClosableMap[schedule.dayIndex]) {
			return `Remove Duplicate Days to Close`;
		}
	})();

	return [
		{
			key: 'closed',
			label: (
				<Tooltip title={tooltipTitle}>
					<Checkbox
						defaultChecked={isStoreClosed(schedule)}
						checked={isStoreClosed(schedule)}
						disabled={
							storeClosableMap[schedule.dayIndex] ||
							disabled ||
							isOpen24hours(schedule)
						}
						onChange={() => onCheckboxChange(index)}
						className="edit-menu__menu-hours-checkbox"
					>
						{closedLabel}
					</Checkbox>
				</Tooltip>
			),
		},
		{
			key: 'twenty-four-hours',
			label: (
				<Checkbox
					label="Open 24 Hours"
					defaultChecked={isOpen24hours(schedule)}
					checked={isOpen24hours(schedule)}
					disabled={
						storeClosableMap[schedule.dayIndex] ||
						disabled ||
						isStoreClosed(schedule)
					}
					onChange={() => on24HoursCheckboxChange(index)}
					className="edit-menu__menu-hours-checkbox"
				>
					Open 24 Hours
				</Checkbox>
			),
		},
		{
			key: 'delete',
			label: (
				<Button
					size="small"
					danger
					className={'edit-menu__menu-hours-delete'}
					icon={!isSourceChowNow && <Trash size={16} />}
					type="link"
					disabled={disabledOrCantBeDeleted}
					onClick={() => handleDeleteSchedule(index)}
				>
					Delete Hours
				</Button>
			),
		},
	];
};
