import React from 'react';

import dayjs from 'dayjs';
import { AlertTriangle } from 'react-feather';

import { appColors } from 'constants/colors.constants';
import {
	FRESH_KDS,
	PAYMENTS,
	POINT_OF_SALE_RESOURCE,
	POS,
	REFERRAL_PARTNERS,
} from 'constants/integration.constants';
import { KIOSK } from 'constants/kiosk.constants';

export const DATE_FORMAT = 'YYYY/MM/DD hh:mm:ss a';
export const DATE_HOUR_FORMAT = 'hh:mm:ss a';
export const DATE_CUBOH_INCEPTION = '2018-01-01 12:00:00 am';
export const DATE_MDY = 'MM/DD/YYYY';
export const DATE_MONTH_DAY = 'MMM DD';
export const LOCAL_LAST_SAVE_DATE = 'MMMM Do YYYY, h:mm:ss a';
export const CANADA = 'canada';

export const timeframe = [
	{ label: 'Today', value: [dayjs().startOf('day'), dayjs().endOf('day')] },
	{
		label: 'Yesterday',
		value: [
			dayjs().add(-1, 'd').startOf('day'),
			dayjs().add(-1, 'd').endOf('day'),
		],
	},
	{
		label: 'Last 7 Days',
		value: [
			dayjs().add(-7, 'd').startOf('day'),
			dayjs().add(-1, 'd').endOf('day'),
		],
	},
	{
		label: 'Last 14 Days',
		value: [dayjs().add(-14, 'd'), dayjs().add(-1, 'd').endOf('day')],
	},
	{
		label: 'This Month',
		value: [dayjs().startOf('month'), dayjs().endOf('month')],
	},
	{
		label: 'Last Month',
		value: [
			dayjs().add(-1, 'M').startOf('month'),
			dayjs().add(-1, 'M').endOf('month'),
		],
	},
];

/** Enum for different notification tasks within the application */
export const notificationType = {
	integration: 'INTEGRATION',
	orchestrator: 'ORCHESTRATOR',
	migration: 'MIGRATION',
};

export const orchestratorStatus = {
	provisioning: 'PROVISIONING',
	running: 'RUNNING',
	done: 'SUCCESSFULLY DONE',
	error: 'ERROR!',
};

export const orchestratorStatusProps = (status) => {
	switch (status) {
		case orchestratorStatus.done:
			return {
				strokeColor: appColors.success,
				status: 'success',
			};
		case orchestratorStatus.error:
			return {
				strokeColor: appColors.danger,
				status: 'error',
				icon: <AlertTriangle size="16" />,
			};
		default:
			return {
				strokeColor: appColors.info,
				status: 'active',
			};
	}
};

export const timezones = [
	'US/Eastern',
	'US/Central',
	'US/Mountain',
	'US/Pacific',
	'US/Alaska',
	'US/Hawaii',
];

export const timezoneMapToChowNowLabel = {
	'US/Eastern': 'EST',
	'US/Central': 'CST',
	'US/Mountain': 'MST',
	'US/Pacific': 'PST',
	'US/Alaska': 'AKST',
	'US/Hawaii': 'HST',
};

export const WEEKDAYS = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];

export const colors = [
	{ app: 'CUBOH', fill: '#16173e' },
	{ app: 'KIOSK', fill: '#89ad95' },
	{ app: 'UBEREATS', fill: '#8699A8' },
	{ app: 'CHOWNOW', fill: '#FEC8FF' },
	{ app: 'GRUBHUB', fill: '#9E8AB8' },
	{ app: 'RITUAL', fill: '#D4F182' },
	{ app: 'DOORDASH', fill: '#CC9271' },
	{ app: 'ALLSET', fill: '#CBD5DC' },
	{ app: 'EATSTREET', fill: '#C697C7' },
	{ app: 'SOCIAVORE', fill: '#A1ECBB' },
	{ app: 'SMOOTHCOMMERCE', fill: '#D3C2E8' },
	{ app: 'Mixbowl', fill: '#C2D689' },
	{ app: 'MODUURN', fill: '#FFBE99' },
	{ app: 'SKIP', fill: '#A4C2D8' },
	{ app: 'FLIPDISH', fill: '#E9D8EA' },
	{ app: 'MIXBOWL', fill: '#D1E9D9' },
	{ app: null, fill: '#98C1A6' },
	{ app: null, fill: '#EOEDBD' },
	{ app: null, fill: '#f2D5C5' },
	{ app: null, fill: '#2C35D3' },
	{ app: null, fill: '#AE6051' },
];

export const INTEGRATION_ACTIONS = {
	CONNECT: 'connect',
	SUCCESS: 'success',
	MENU_SELECT: 'menuSelect',
};

export const storefrontPath = {
	edit: 'edit',
	default: 'DEFAULT',
};

export const REFRESH = 'refresh';
export const ACCESS = 'access';
export const CUBOH_ACCESS = 'cuboh_access';
export const CUBOH_REFRESH = 'cuboh_refresh';

export const DEFAULT_TRUNCATE_LIMIT = 45;
export const DEFAULT_MESSAGE_DURATION = 60;
export const LOADING_NUM_ROWS = 40;
export const INVALID_INDEX = -1;

export const PATHS_TO_HIDE_MERCHANTS = [
	'/',
	'/manage/payments',
	'/manage/storefronts',
	'/manage/kiosk',
	'/manage/pos-settings',
	'/manage/fresh-kds',
	'/manage/v2/integrations/pos/oauth/square/success',
	'/manage/v2/integrations/pos/oauth/square/failure',
];

export const PATHS_TO_SHOW_STATIC_COMPANY_LABEL = [
	'/manage/storefronts',
	'/manage/kiosk',
];

export const QUERY_PATHS_TO_HIDE_MERCHANTS = [
	PAYMENTS,
	REFERRAL_PARTNERS,
	FRESH_KDS,
	POINT_OF_SALE_RESOURCE,
	POS,
];

export const ACCEPTED_IMAGE_FILE_TYPES = [
	'image/png',
	'image/jpeg',
	'image/jpg',
];

export const CUBOH_TAGS = {
	ACCEPTING: 'accepting',
	PAUSED: 'paused',
	NEW: 'new',
	PICKUP_ONLY: 'pickup-only',
	FAILED_UPDATE: 'unavailable',
	UNSUPPORTED: 'unsupported',
	MASTER_MENU: 'master',
	MASTER_ITEM: 'master_item',
	LINKED_MENU: 'linked_menu',
	PUBLISHED: 'published',
	IMPORT_IN_PROGRESS: 'import_in_progress',
	FAILED: 'failed',
	IN_PROGRESS: 'in_progress',
	MODIFIED: 'modified',
	INACTIVE: 'inactive',
	IMPORT_SOURCE: 'import_source',
	ERROR: 'error',
	SHARED_ITEM: 'shared',
	STORE_CLOSED: 'store_closed',
	STORE_OPEN: 'store_open',
	DISABLED: 'disabled',
};

export const PROMISE_OPTIONS = {
	RESOLVED: 'fulfilled',
	REJECTED: 'rejected',
};

export const LOG_LEVEL = {
	INFO: 'info',
	ERROR: 'error',
};

export const COMPANY_ID = 'companyID';
export const MERCHANT_ID = 'merchantID';

export const MILES_TO_KILOMETERS_MULTIPLIER = 1.609344;
export const KILOMETERS_TO_MILES_MULTIPLIER = 0.621371;

export const TOOLTIP_TEXT = {
	taxRate:
		'Please note that this tax rate will apply for all merchants under this company account. In case you have merchants with a different local tax rate, you will have to manually update this on your menu(s).',
};

export const INTEGRATION_FINISH = 'finish';

export const STORE_STATUS_UPDATED = 'STORE_STATUS_UPDATED';
export const POS_EXCEPTION = 'POS_EXCEPTION';

export const DEFAULT_MAX_ZOOM = 18;

export const LOCAL_HOSTNAMES = [
	'localhost',
	'127.0.0.1',
	`${KIOSK}.localhost`,
	`${KIOSK}.127.0.0.1`,
];

export const STOREFRONT_APP_NAMES = ['CUBOH', 'KIOSK'];
export const IMAGE_CONTENT_REQUEST_TYPE = 'image/jpeg';

export const pos = 'pos';
