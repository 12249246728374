// Actions
export const SET_INTEGRATION_LOADING = 'SET_INTEGRATION_LOADING';
export const SET_INTEGRATION_ERROR = 'SET_INTEGRATION_ERROR';
export const SET_INTEGRATION_DATA = 'SET_INTEGRATION_DATA';
export const SET_INTEGRATION_APPS = 'SET_INTEGRATION_APPS';
export const COUNTDOWN_TICK = 'COUNTDOWN_TICK';
export const DISABLE_INTEGRATIONS = 'DISABLE_INTEGRATIONS';
export const RELOAD_DISABLE_INTEGRATIONS = 'RELOAD_DISABLE_INTEGRATIONS';
export const ALL_MERCHANTS = 'ALL_MERCHANTS';
export const ALL_PLATFORMS = 'ALL_PLATFORMS';

// Misc
export const INTEGRATION_ACTIVE = 1;
export const INTEGATION_INACTIVE = 0;
export const DISABLE_INTEGRATION_DURATION_SECONDS = 30;
export const DISABLE_INTEGRATION_INTERVAL_MS = 5000;
export const PAGINATION_DEFAULT_PAGE_SIZE = 5;
export const RESET_TIME_AS_INT = 400;
export const SHOOTER_ENABLED_SCOPE = 'shoot_orders';

// API response text
export const ACTIVE_STATUS = 'active';
export const INACTIVE_STATUS = 'inactive';
export const FAILED_UPDATE = 'failure';
export const SUCCESS_UPDATE = 'success';
export const UNSUPPORTED_PLATFORM = 'unsupported';

export const ACTIVATE = 'activate';
export const DEACTIVATE = 'deactivate';

export const ACTIVATED = 'activated';
export const DEACTIVATED = 'deactivated';

// Local storage
export const TIMESTAMPED_INTEGRATIONS = 'timestampedIntegrations';
